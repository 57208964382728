toright();

let pickers = [];

function toleft() {
    if (jQuery('.sites').length > 0) {
        jQuery('.sites').animate({scrollLeft: websitesWidth()}, websitesAmount() * 5000, 'linear');
        setTimeout(function () {
            toright();
        }, websitesAmount() * 1000);
    }
}

function toright() {
    if (jQuery('.sites').length > 0) {
        jQuery('.sites').animate({scrollLeft: (websitesWidth() * -1)}, websitesAmount() * 5000, 'linear');
        setTimeout(function () {
            toleft();
        }, websitesAmount() * 1000)
    }
}

function websitesAmount() {
    return jQuery('.sites > .website').length;
}

function websitesWidth() {
    var width = 0;
    jQuery('.sites > .website').each(function (index, item) {
        width += jQuery(item).width();
    });

    return width;
}

jQuery('.todo-list__label input').removeAttr('disabled');

jQuery(document).ready(function () {
    jQuery('.side-menu').on('click', function (e) {
        if (jQuery(this).attr('href') == undefined) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            jQuery(this).parent().toggleClass('active');
            jQuery(this).toggleClass('side-menu--open');
            jQuery(this).parent().find('> ul').toggleClass('side-menu__sub-open');
            jQuery(this).find('.side-menu__sub-icon svg').toggleClass('transform rotate-180');
        }
    })

    jQuery('.mobile-menu .menu').on('click touch', function (e) {
        if (jQuery(this).attr('href') == undefined) {
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            jQuery(this).parent().toggleClass('active');
            jQuery(this).toggleClass('side-menu--open');
            jQuery(this).parent().find('> ul').toggleClass('menu__sub-open');
            jQuery(this).find('> .side-menu__sub-icon svg').toggleClass('transform rotate-180');
        }
    })

    jQuery(document).on('click touch', '.mobile-menu-toggler', function () {
        jQuery('.mobile-menu').toggleClass('mobile-menu--active');
    })

    jQuery(document).keydown("f", function (event) {
        if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.which === 70) {
            event.preventDefault();

            if(jQuery('.bord-search').length > 0) {
                jQuery('.bord-search input').focus();
            } else {
                jQuery('.top-bar-boxed .search input').focus();
            }

        }
    });

    let lastShiftPress = 0;

    jQuery(document).keydown(function(event) {
        if (event.key === "Shift") {
            let now = Date.now();
            if (now - lastShiftPress <= 300) {
                jQuery('.top-bar-boxed .search').click();
            }
            lastShiftPress = now;
        }
    });

    jQuery(document).on('mouseenter', '.item-hover-row', function() {
        const itemId = $(this).data('item-id');

        if (itemId !== null && itemId !== undefined) {
            $(this).addClass('bg-slate-300');
            $('.item-row[data-item-id="' + itemId + '"]').addClass('bg-slate-300');
        }
    });

    jQuery(document).on('mouseleave', '.item-hover-row', function() {
        const itemId = $(this).data('item-id');

        if (itemId !== null && itemId !== undefined) {
            $(this).removeClass('bg-slate-300');
            $('.item-row[data-item-id="' + itemId + '"]').removeClass('bg-slate-300');
        }
    });
});
